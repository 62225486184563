// api
export const API_URL = `https://api.lookonline.ru/api/`;

// maintenance mode
export const AUTH_PASSWORD = "mylook2022";
export const TECH_WORKS_ID = null; /* set null to hide tech works popup */
export const TECH_WORKS_END_TIME = "03.04.2022 после 15:00";
export const IS_PASSWORD_REQUIRED_PAGE = false;
export const IS_MAINTENANCE_PAGE = false;

// chat
export const CHAT_SOCKET_URL = 'wss://chat.lookonline.ru:9001';
export const CHAT_TOKEN = "034d7cea-8e78-4bbb-9878-00ee23dc80f0";
export const CHAT_ALLOW_INSECURE = false;

// metrics
export const YANDEX_METRIC = "73341586";
export const GOOGLE_ANALYTICS = "G-C16D6FB9VE";
export const YM_JS = true;

// boxberry
export const BOXBERRY_TOKEN = "1$c5a41c4443db1b3bcf88519135346647";

// payture
export const DEV_PAYMENT = false;
export const PAYTURE_VWID = "VWLookOnlineBRSPay";
export const PAYTURE_CARDS_VWID = "VWLookOnlineBRS3DSAdd";
export const PAYTURE_SBP_VWID = "LookOnlineBRSSBP_SBP";
export const SYSTEM_TOKEN = "huUPc9YwUYdtpJ646Q4is4Mdi9nFeNTQ";
export const CHEQUE_API_PASSWORD = "6uN12D7m";
export const PAYTURE_INN = "7734438295";
export const SLACK_WEBHOOK_URI =
  "https://hooks.slack.com/services/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";

// banner
export const BANNER_URI = "/static/img/banner_dec_9.jpg";
export const BANNER_DISCOUNTS: (number | string)[] = [30, 50, 70]; // 20, 30, 50, 70, "one_plus_one"

// other
export const PRODUCT_LOW_THRESHOLD = 3;
export const YANDEX_MAP_API_KEY = "d14bcab9-73c8-44de-a8c4-786d1f01636d";
export const YANDEX_MAP_SUGGEST_API_KEY =
  "feeff461-64d3-4a90-b486-bbd064f08a01";
export const BLOCKS_PER_PAGE = 12;
export const SALT =
  "v8724n&^a3rf34i4tgrgls439$#Th$%T#$Grg3^465y6uj67t5@#%$#THr";
export const IS_SENTRY_ENABLED = false;
export const SLACK_LOGS_WEBHOOK =
  "https://hooks.slack.com/services/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";
export const NOINDEX = false;

export const T_BANK = {
  host: "https://securepay.tinkoff.ru/v2",
  terminalKey: "1721742763533",
  password: "TrnhIcLfmSpDTq0F",
};
export const CLOUDPAYMENTS = {
  public_id: "pk_e18add7867322b48d7d87d2463e57",
};
